import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLazyQuery } from "@apollo/react-hooks"
// bca expo form
import { withFormik, Form, Field } from "formik"
import Select from "react-select"
import * as Yup from "yup"
import NumberFormat from "react-number-format"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"
import { Carousel } from "../../components/utils/render-functions"
import Modal from "react-modal"
import OtpInput from "react-otp-input"
import { useMediaPredicate } from "react-media-hook"
// end bca expo form

import "../../graphql/fragments"

import SEO from "../../components/seo"
import Layout from "../../components/layout"

import { CoverMain } from "../../components/cover-main/cover-main"
import { HeadingBasic } from "../../components/heading/heading"
import { Section, Container } from "../../components/grid/grid"
import { Cover } from "../../components/cover/cover"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const GET_OTP = gql`
  mutation getOtp($phoneNumber: String) {
    requestOtp(input: { clientMutationId: "", phoneNumber: $phoneNumber }) {
      status
      message
    }
  }
`

const GET_PHONE_EMAIL = gql`
  query formBCAExpo {
    formBCAExpo {
      email
      phoneNumber
    }
  }
`

const SUBMIT_FORM = gql`
  mutation Submit2BcaExpo(
    $fullName: String
    $phoneNumber: String
    $email: String
    $product: String
    $otpCode: String
  ) {
    submit2BcaExpo(
      input: {
        clientMutationId: ""
        fullName: $fullName
        phoneNumber: $phoneNumber
        email: $email
        product: $product
        otpCode: $otpCode
      }
    ) {
      status
      message
    }
  }
`
const listOfProduct = [
  { label: "Aerium Residence", value: "Aerium Residence" },
  { label: "Element Tower 1", value: "Element Tower 1" },
  { label: "Element Tower 2", value: "Element Tower 2" },
  { label: "Southgate Altuerra", value: "Southgate Altuerra" },
  { label: "Southgate Elegance", value: "Southgate Elegance" },
  { label: "Southgate Prime", value: "Southgate Prime" },
  { label: "Klaska Residence, Tower Azure", value: "Klaska Residence, Tower Azure" },
  { label: "The Nove, Tower Kaina", value: "The Nove, Tower Kaina" },
  { label: "The Nove, Tower Kalani", value: "The Nove, Tower Kalani" },
  { label: "Casa De Parco, Tower Cassea", value: "Casa De Parco, Tower Cassea" },
  { label: "Casa De Parco, Tower Gardenia", value: "Casa De Parco, Tower Gardenia" },
  { label: "Casa De Parco, Tower Magnolia", value: "Casa De Parco, Tower Magnolia" },
  { label: "Cluster Asatti", value: "Cluster Asatti" },
  { label: "Cloverville Kavling", value: "Cloverville Kavling" },
  { label: "Alexandria", value: "Alexandria" },
  { label: "Street of London", value: "Street of London" },
  { label: "Street of Paris", value: "Street of Paris" },
  { label: "Palais de Luxembourg", value: "Palais de Luxembourg" },
  { label: "Grand Palais", value: "Grand Palais" },
  { label: "The Nove, Kavling Phase 2", value: "The Nove, Kavling Phase 2" },
  { label: "The Nove, Kavling Phase 1", value: "The Nove, Kavling Phase 1" },
  { label: "Freja Suites", value: "Freja Suites" },
  { label: "AMARINE", value: "AMARINE" },
  { label: "Amata", value: "Amata" },
  { label: "Blossom Ville Ex", value: "Blossom Ville Ex" },
  { label: "Royal Blossom", value: "Royal Blossom" },
  { label: "Giri Loka", value: "Giri Loka" },
  { label: "Ingenia", value: "Ingenia" },
  { label: "Precia", value: "Precia" },
  { label: "VIVACIA", value: "VIVACIA" },
  { label: "Anila House", value: "Anila House" },
  { label: "Assana House", value: "Assana House" },
  { label: "Azura House", value: "Azura House" },
  { label: "DE MAJA", value: "DE MAJA" },
  { label: "HYLANDS", value: "HYLANDS" },
  { label: "LUXMORE", value: "LUXMORE" },
  { label: "Cattleya", value: "Cattleya" },
  { label: "Plot A", value: "Plot A" },
  { label: "Albera", value: "Albera" },
  { label: "Nusa Loka", value: "Nusa Loka" },
  { label: "Da Vinci", value: "Da Vinci" },
  { label: "Autoparts", value: "Autoparts" },
  { label: "Kios Pasar Modern 2/ Barat", value: "Kios Pasar Modern 2/ Barat" },
  { label: "Grand Palais", value: "Grand Palais" },
  { label: "Garden Suite", value: "Garden Suite" },
  { label: "Alfiore", value: "Alfiore" },
  { label: "Kios Pasar Modern", value: "Kios Pasar Modern" },
  { label: "Cluster Whitsand II", value: "Cluster Whitsand II" },
  { label: "Cluster Whelford", value: "Cluster Whelford" },
  { label: "Rumah Zena", value: "Rumah Zena" },
  { label: "Alegria Park", value: "Alegria Park" },
  { label: "Cluster Alesha", value: "Cluster Alesha" },
  { label: "Cluster Assana", value: "Cluster Assana" },
  { label: "Cluster Assana Starlight", value: "Cluster Assana Starlight" },
  { label: "Batam Landed Phase 2 (Blok EFGH)", value: "Batam Landed Phase 2 (Blok EFGH)" },
  { label: "Batam Landed Phase 1 (Blok ABCD)", value: "Batam Landed Phase 1 (Blok ABCD)" },
  { label: "IMAJIHAUS", value: "IMAJIHAUS" },
  { label: "Impresahaus", value: "Impresahaus" },
  { label: "The Savia Thp 1", value: "The Savia Thp 1" },
  { label: "Visana at The Savia", value: "Visana at The Savia" },
  { label: "Cluster Caelus", value: "Cluster Caelus" },
  { label: "Cluster Azura Starlight", value: "Cluster Azura Starlight" },
  { label: "Delrey Business Townhouse 2", value: "Delrey Business Townhouse 2" },
  { label: "Taman Tekno X (ruko, gudang, kav.hoek)", value: "Taman Tekno X (ruko, gudang, kav.hoek)" },
  { label: "Northridge", value: "Northridge" },
  { label: "Ruko Madrid X", value: "Ruko Madrid X" },
  { label: "Ruko Icon 3", value: "Ruko Icon 3" },
  { label: "Cheville", value: "Cheville" },
  { label: "Paddington", value: "Paddington" },
  { label: "Nordville", value: "Nordville" },
  { label: "Hayfield", value: "Hayfield" },
  { label: "London Park Avenue Thp 2", value: "London Park Avenue Thp 2" },
  { label: "Breezy House", value: "Breezy House" },
  { label: "Cozmo House", value: "Cozmo House" },
  { label: "The Blizfield", value: "The Blizfield" },
  { label: "Latinos Business Dis Thp 3", value: "Latinos Business Dis Thp 3" },
  { label: "Tanakayu Jiva", value: "Tanakayu Jiva" },
  { label: "Tanakayu Svani", value: "Tanakayu Svani" },
  { label: "Tanakayu Svadhi", value: "Tanakayu Svadhi" },
  { label: "Tanakayu Svasti", value: "Tanakayu Svasti" },
  { label: "Aerra", value: "Aerra" },
  { label: "Grand Freja", value: "Grand Freja" },
  { label: "Tanakayu Vasya", value: "Tanakayu Vasya" },
  { label: "New Alesha", value: "New Alesha" },
  { label: "Vanya Avenue", value: "Vanya Avenue" },
  { label: "Excelia", value: "Excelia" },
  { label: "Virginia Arcade Tahap 2", value: "Virginia Arcade Tahap 2" }
];

const LeadForm = ({ errors, touched, values, setFieldValue, btnLoading }) => {
  const phoneNumberHandler = (val, setFieldValue) =>
    setFieldValue("phoneNumber", val)

    const [checked, setChecked] = useState(false)

    function handleOnChange(){
      setChecked(!checked)
    }

  return (
    <Form className="row pt-4" id="surpriseForm">
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Nama</label>
        <Field
          name="name"
          className="form-control px-0"
          placeholder="Nama Anda"
          type="text"
          id="customerName"
          value={values.fulllName}
        />
        {touched.name && errors.name && (
          <div className="invalid-value d-block">{errors.name}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Nomor Handphone</label>
        <NumberFormat
          name="phoneNumber"
          className="form-control px-0"
          placeholder="Nomor Handphone"
          allowEmptyFormatting
          prefix="+62"
          value={values.phoneNumber}
          onValueChange={(val) =>
            phoneNumberHandler(val.formattedValue, setFieldValue)
          }
          id="customerPhone"
        />
        {touched.phoneNumber && errors.phoneNumber && (
          <div className="invalid-value d-block">{errors.phoneNumber}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Email</label>
        <Field
          name="email"
          className="form-control px-0"
          placeholder="Email Anda"
          type="text"
          id="customerEmail"
          value={values.email}
        />
        {touched.email && errors.email && (
          <div className="invalid-value d-block">{errors.email}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Produk Yang Diminati</label>
        <Select
          maxMenuHeight={150}
          className="form-control px-0"
          classNamePrefix="Produk Yang Diminati"
          options={listOfProduct}
          defaultValue={{ value: "", label: "Produk Yang Diminati" }}
          id="product"
          onChange={(e) => {
            setFieldValue("product", e.value)
          }}
        />
        {touched.product && errors.product && (
          <div className="invalid-value d-block">{errors.product}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3 pt-3">
        <Field 
          type="checkbox" 
          name="tnc" 
          value="tnc"
          id="tnc"
          onClick={handleOnChange}
        />
        &nbsp;<span className="tnc-giias">Saya setuju dengan syarat & ketentuan yang berlaku.</span><span className="red-star" style={{color:"#ab0204"}}>*</span>
      </div>
      <div className="col-12 pt-5">
        <button className="btn btn-primary w-100 w-md-25">
          {btnLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
        </button>
      </div>
    </Form>
  )
}

const BcaExpoPage = ({ location }) => {
  const staticData = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerResidence.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      imgPropertyFinder: file(relativePath: { eq: "residence-finder.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      featured: file(relativePath: { eq: "img_development-main.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        page(id: "bca-expo", idType: URI) {
          title
          featuredImage {
            mediaItemUrl
          }
          blocks {
            name
            ... on WordPress_CoreHeadingBlock {
              attributes {
                content
              }
            }
            ... on WordPress_CoreCoverBlock {
              attributes {
                url
                className
              }
            }
            ... on WordPress_CoreParagraphBlock {
              attributes {
                ... on WordPress_CoreParagraphBlockAttributesV3 {
                  content
                }
              }
            }
            ... on WordPress_AcfAcfgalleryBlock {
              name
              acf {
                carousel_type
                images {
                  title
                  mediaDetails {
                    file
                    height
                    width
                  }
                  slug
                  sourceUrl
                  acfAttachment {
                    location
                    expired
                  }
                  caption(format: RAW)
                  description(format: RAW)
                }
              }
            }
            ... on WordPress_CoreEmbedBlock {
              attributes {
                url
              }
            }
          }
          translation(language: ID) {
            title
            blocks {
              name
              ... on WordPress_CoreHeadingBlock {
                attributes {
                  content
                }
              }
              ... on WordPress_CoreCoverBlock {
                attributes {
                  url
                  className
                }
              }
              ... on WordPress_CoreParagraphBlock {
                attributes {
                  ... on WordPress_CoreParagraphBlockAttributesV3 {
                    content
                  }
                }
              }
              ... on WordPress_AcfAcfgalleryBlock {
                name
                acf {
                  carousel_type
                  images {
                    title
                    mediaDetails {
                      file
                      height
                      width
                    }
                    slug
                    sourceUrl
                    acfAttachment {
                      location
                      expired
                    }
                    caption(format: RAW)
                    description(format: RAW)
                  }
                }
              }
              ... on WordPress_CoreEmbedBlock {
                attributes {
                  url
                }
              }
            }
          }
          seo {
            title
            twitterTitle
            twitterDescription
            metaDesc
            opengraphTitle
            opengraphDescription
          }
        }
      }
    }
  `)

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }

  const ogUrl = process.env.BASE_URL + location.pathname
  const wordPress = staticData.wordPress.page
  const seo = staticData.wordPress.page?.seo
  const resizeScreen = useMediaPredicate("(max-width: 992px)")

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    product: "",
    tnc: "",
  })
  
  const [showModal, setShowModal] = useState(false)
  const [otp, setOtp] = useState()
  // eslint-disable-next-line
  const [successModal, setSuccessModal] = useState(false)
  const [otpError, setOtpError] = useState(false)
  const [timeLimit, setTimeLimit] = useState(30)

  // bca expo form
  const [getPhoneEmail, { data }] = useLazyQuery(GET_PHONE_EMAIL)
  const [submitForm, { loading }] = useMutation(SUBMIT_FORM)
  const [getOtp, { loading: getOtpLoading }] = useMutation(GET_OTP)

  const [coverItems, setCoverItems] = useState([])

  const iframeTriggerUpdate = () => {
    var a = document.createElement("iframe")
    a.setAttribute("src","https://fork.gotrackier.com/pixel?adid=64feaf06cfbccb20802b07d1&goal_value=SubmitButton")
    a.style.width="1"
    a.style.height="1"
    document.body.appendChild(a)
  }

  useEffect(() => {
    let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")
    let vLine = document.querySelector(".v-line")
    let langItem = document.querySelectorAll(".lang-item")
    let langItemActive = document.querySelector(".lang-item.active")
      
    if(resizeScreen){
      document.getElementsByClassName("logo-secondary")[1].style.display = `none`
      document.getElementsByClassName("logo-main")[1].style.display = `block`
    }else{
      document.getElementsByClassName("logo-secondary")[0].style.display = `none`
      document.getElementsByClassName("logo-main")[0].style.display = `block`
    }

    for (let i = 0; i < navbarDesktop.length; i++) {
      navbarDesktop[i].style.color = "#000"
    }

    for (let i = 0; i < langItem.length; i++) {
      langItem[i].style.color = "#000"
    }

    vLine.style.backgroundColor = "#000"
    langItemActive.style.color = "#AB0204"

    getPhoneEmail()

    let coverPage = document.querySelectorAll(".bg-overlay.bg-overlay-40")

    for (let i = 0; i < coverPage.length; i++) {
      coverPage[i].className = "bg-overlay bg-overlay-10"
    }

    if(resizeScreen){
      if(document.querySelector(".cover-full-top")){document.querySelector(".cover-full-top").style.marginTop = "60px"}
      document.getElementsByClassName("logo-secondary")[1].style.display = `none`
      document.getElementsByClassName("logo-main")[1].style.display = `block`
    }
  }, [])

  useEffect(() => {
    wordPress && wordPress?.blocks?.map(item => {
      if(item.name === "core/cover"){
        setCoverItems((prev)=>[...prev, {url: item.attributes?.url, className: item.attributes?.className}])
      }
    })
  }, [resizeScreen, wordPress])

  useEffect(() => {
    let timer = setTimeout(() => {
      if (timeLimit >= 0 && showModal) {
        setTimeLimit(timeLimit - 1)
      }
    }, 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [timeLimit, showModal])

  const EnhancedLeadForm = withFormik({
    mapPropsToValues({ name, phoneNumber, email, product, tnc, }) {
      return {
        name: formValues.name || name,
        phoneNumber: formValues.phoneNumber || phoneNumber,
        email: formValues.email || email,
        product: formValues.product || product,
        tnc: formValues.tnc || tnc,
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      email: Yup.string()
        .email()
        .required("Email is required"),
      product: Yup.string().required("Produk Yang Dibeli is required"),
      tnc: Yup.string().required("TnC is required"),
    }),
    handleSubmit(values) {
      setFormValues(values)
      const formatPhone = values?.phoneNumber?.split("+").join("")

      // TODO : reject submit if phoneNumber/email exist
      let dataList = data.formBCAExpo

      // for (let i = 0; i < dataList.length; i++) {
      //   if (
      //     dataList[i].phoneNumber == formatPhone ||
      //     dataList[i].email.trim() == values.email.trim()
      //   ) {
      //     alert("Maaf! Anda tidak bisa mengikuti program kembali")
      //     return false
      //   }
      // }

      iframeTriggerUpdate()
      getOtp({ variables: { phoneNumber: formatPhone } }).then(res => {
        if (res.data.requestOtp.status === "success") {
          setShowModal(true)
        }
      })

      // submitForm({
      //   variables: {
      //     fullName: values?.name,
      //     phoneNumber: formatPhone,
      //     email: values?.email,
      //     product: values?.product,
      //   },
      // })
      //   .then((res) => {
      //     console.log(res)
      //     alert("Terima Kasih, data Anda telah disimpan.")
      //     window.location.reload()
      //   })
      //   .catch((e) => {
      //     console.log(e)
      //     alert("Terjadi kesalahan saat minyimpan data.")
      //   })
    },
  })(LeadForm)

  const handleOtp = otp => {
    setOtp(otp)
  }

  const getOtpHandler = () =>
    getOtp({
      variables: { phoneNumber: formValues.phoneNumber.split("+").join("") },
    }).then(res => {
      if (res.data.requestOtp.status === "success") {
        setTimeLimit(30)
        setShowModal(true)
      }
    })
  
  const sendOtp = () => {
    const formatNumber = formValues.phoneNumber.split("+").join("")

    submitForm({
      variables: {
        fullName: formValues?.name,
        phoneNumber: formatNumber,
        email: formValues?.email,
        product: formValues?.product,
        otpCode: otp,
      },
    }).then((res) => {
      // console.log(res)
      // alert("Terima Kasih, data Anda telah disimpan.")
      // window.location.reload()
      if (res.data.submit2BcaExpo.status === "success") {
        setShowModal(false)
        setSuccessModal(true)
        setTimeLimit(30)
        setFormValues({ fullName: "", email: "", phoneNumber: "", product: "" })
        setOtp("")
        if (otpError) {
          setOtpError(false)
        }
        alert("Terima Kasih, data Anda telah disimpan.")
        window.location.reload()
      }else{
        setOtpError(true)
      }
    }).catch((e) => {
      // console.log(e)
      alert("Terjadi kesalahan saat minyimpan data.")
    })
  }

  // const cover = wordPress?.blocks?.find((item) => item.name === "core/cover")
  const featuredImage = staticData?.wordPress?.page?.featuredImage?.mediaItemUrl

  return (
    <Layout location={location} currentLocation={location.pathname} lang="EN">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle || seo?.title}
        twitterTitle={seo?.twitterTitle || seo?.title}
        url={ogUrl}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        twitterImg={featuredImage}
        twitterDescription={seo?.twitterDescription || seo?.opengraphDescription}
        img={featuredImage}
        fbImg={featuredImage}
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>
      {/*  Change to image render because the image is informational/can't be cut off
      <CoverMain
        h1={""}
        img={resizeScreen ? coverItems[1]?.url : coverItems[0]?.url}
        alt={wordPress?.title}
        className={`cover-md ${resizeScreen ? coverItems[1]?.className : coverItems[0]?.className} cover-header-70`}
      /> */}
      <img
        src={resizeScreen ? coverItems[1]?.url : coverItems[0]?.url}
        className="w-100"
        alt={wordPress?.title}
      />
      {wordPress?.blocks.map((item, i) => {
        switch (item.name) {
          case "core/heading":
            return (
              <div key={`heading-${i}`} className="container container-md">
                <div className={i === 0 ? "py-main pb-3 pt-4" : "p-0"}>
                  <HeadingBasic h2={item.attributes.content} />
                </div>
              </div>
            )
          case "core/paragraph":
            return (
              <div
                key={`headingbasic-${i}`}
                className={`py-main pb-3 pt-3`}
              >
                <div className="container container-md">
                  {/* <HeadingBasic h2={wordPress?.title} /> */}
                  <HeadingBasic text={[item.attributes.content]} />
                </div>
              </div>
            )
          case "acf/acfgallery":
            return (
              <Container
                className={"pb-main"}
                key={`gallery-${i}`}
              >
                <Carousel data={item} />
              </Container>
            )
          case "core/embed":
            return (
              <div className="container py-main">
                <Cover
                  variant="basic"
                  theme="dark"
                  img={null}
                  imgHeight="h-400px h-md-500px w-100"
                  // imgOverlay="40"
                  className="cover cover-full-top"
                  // type="video-popup"
                  videoSrc={item.attributes && item.attributes?.url}
                  type={{ name: "core-embed/youtube" }}
                  audio={false}
                />
              </div>
            )
          default:
            return <></>
        }
      })}
      {/* Hide bca form */}
      {/* <div
        className={`mb-5`}
      >
        <div className="container container-md">
          <HeadingBasic h2={"Saya Ingin Penawaran Terbaik"} />
          <EnhancedLeadForm />
        </div>
      </div> */}
      <Modal
        isOpen={showModal}
        style={customStyles}
        contentLabel="campaign modal"
        overlayClassName="modal-overlay-general"
      >
        <div className="modal-popup-otp modal-body text-center">
          <div className="container py-5 mw-md-75 mw-100">
            <h2>OTP</h2>
            <p className="m-0">
              Untuk melanjutkan, silakan masukkan 6 digit kode yang
              dikirimkan ke nomor ponsel Anda
            </p>
            <p className="font-weight-bold">{formValues.phoneNumber}</p>
            <OtpInput
              value={otp}
              containerStyle="otp-container"
              inputStyle={`otp-input ${otpError ? "error" : ""}`}
              onChange={handleOtp}
              numInputs={6}
            />
            {otpError && (
              <p className="otp-error mt-3">
                The code you entered does not match, please re-enter.
              </p>
            )}
            {getOtpLoading ? (
              <p className="mt-md-5 mt-3 text-primary">
                <i className="fa fa-spinner fa-spin"></i>
              </p>
            ) : timeLimit > 0 ? (
              <p className="mt-md-5 mt-3 text-muted">
                Tidak menerima kode OTP? kirim ulang ({timeLimit})
              </p>
            ) : (
              <button
                className="btn mt-md-5 mt-3 text-primary"
                onClick={getOtpHandler}
              >
                Haven’t received OTP code? <strong>Resend</strong>
              </button>
            )}
            <button className="btn btn-primary w-50 mt-3" onClick={sendOtp}>
              {loading ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  )
}
export default BcaExpoPage
